import { StaticImage } from 'gatsby-plugin-image';
import { PartnersProps } from '../Partners';
import React from 'react';

const mainLogos = (
  <React.Fragment>
    <StaticImage
      src="../assets/cvs@1x.png"
      height={50}
      alt="Available at CVS"
    />
    <StaticImage
      src="../assets/target@1x.png"
      height={31}
      alt="Available at Target"
    />
    <StaticImage
      src="../assets/amazon@1x.png"
      height={50}
      alt="Available at Amazon"
    />
    <StaticImage
      src="../assets/walmart.png"
      height={50}
      alt="Available at Walmart"
    />
  </React.Fragment>
);

export const PARTNERS_CONTENT: PartnersProps = {
  header: 'Shop Curology at',
  partners: mainLogos,
};
