import { StaticImage } from 'gatsby-plugin-image';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { MultiCategoryHeroProps } from '../MultiCategoryHero';
import React from 'react';
import { PAGE_URLS } from 'consts/pageUrls';

export const HOMEPAGE_MULTICATEGORY_CONTENT: MultiCategoryHeroProps = {
  headline: (
    <>
      Skincare <span>personalized</span> for you
    </>
  ),
  subheadline: `Get closer to your skin and hair goals with a treatment that's customized for you.`,
  cta: {
    buttonText: 'Get started',
    color: 'dark-blue',
    desktopSize: 'Small',
  },
  categories: [
    {
      headline: (
        <>
          Clear <br />
          your skin
        </>
      ),
      image: (
        <React.Fragment>
          <StaticImage
            src="../images/clearSkinDesktop.png"
            alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
            loading="eager"
            objectFit="fill"
            objectPosition="center"
            css={[desktopOnly('block', 'lgUp')]}
          />
          <StaticImage
            src="../images/clearSkinMobile.png"
            alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
            loading="eager"
            objectFit="fill"
            objectPosition="center"
            css={[mobileOnly('block', 'lgUp')]}
          />
        </React.Fragment>
      ),
      link: PAGE_URLS.products.customFormulaAcne,
    },
    {
      headline: (
        <>
          Brighten <br />
          your skin
        </>
      ),
      image: (
        <React.Fragment>
          <StaticImage
            src="../images/hydrateSkinDesktop.png"
            alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
            loading="eager"
            objectFit="fill"
            objectPosition="center"
            css={[desktopOnly('block', 'lgUp')]}
          />
          <StaticImage
            src="../images/hydrateSkinMobile.png"
            alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
            loading="eager"
            objectFit="fill"
            objectPosition="center"
            css={[mobileOnly('block', 'lgUp')]}
          />
        </React.Fragment>
      ),
      link: PAGE_URLS.products.futureProofAntiAging,
    },
    {
      headline: (
        <>
          Regrow <br />
          your hair*
        </>
      ),
      tag: 'New!',
      image: (
        <React.Fragment>
          <StaticImage
            src="../images/regrowHairDesktop.png"
            alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
            loading="eager"
            objectFit="fill"
            objectPosition="center"
            css={[desktopOnly('block', 'lgUp')]}
          />
          <StaticImage
            src="../images/regrowHairMobile.png"
            alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
            loading="eager"
            objectFit="fill"
            objectPosition="center"
            css={[mobileOnly('block', 'lgUp')]}
          />
        </React.Fragment>
      ),
      link: PAGE_URLS.products.hairFormula,
    },
  ],
};
