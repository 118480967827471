import React, { ReactNode } from 'react';

import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';
import { SeeTheDifferenceComparisonTable } from './components/SeeTheDifferenceComparisonTable';

import {
  SeeTheDifferenceBodyContent,
  SeeTheDifferenceContent,
  SeeTheDifferenceCopyAndCtaContainer,
  SeeTheDifferenceDesktopTable,
  SeeTheDifferenceHeading,
  SeeTheDifferenceMobileTable,
  SeeTheDifferenceTableWrapper,
  SeeTheDifferenceWrapper,
} from './SeeTheDifference.style';
import { LOCAL_COLORS } from 'styles/constants';

export interface SeeTheDifferenceProps {
  backgroundColor?: string;
  body?: ReactNode;
  competitorName: ReactNode;
  competitorValueProps: string[];
  ctaButton?: CtaButtonProps;
  heading: ReactNode;
  productName: ReactNode;
  productValueProps: string[];
}

const moduleName = 'seeTheDifference';

export const SeeTheDifference = ({
  backgroundColor = LOCAL_COLORS.surfaceWhite,
  body,
  heading,
  competitorName,
  competitorValueProps,
  ctaButton,
  productName,
  productValueProps,
}: SeeTheDifferenceProps) => (
  <SeeTheDifferenceWrapper
    backgroundColor={backgroundColor}
    data-module={moduleName}
  >
    <SeeTheDifferenceContent>
      <SeeTheDifferenceDesktopTable>
        <SeeTheDifferenceTableWrapper>
          <SeeTheDifferenceComparisonTable
            name={productName}
            valueProps={productValueProps}
          />
          <SeeTheDifferenceComparisonTable
            isCompetitorTable={true}
            name={competitorName}
            valueProps={competitorValueProps}
          />
        </SeeTheDifferenceTableWrapper>
      </SeeTheDifferenceDesktopTable>
      <SeeTheDifferenceCopyAndCtaContainer>
        <SeeTheDifferenceHeading backgroundColor={backgroundColor}>
          {heading}
        </SeeTheDifferenceHeading>
        {body && (
          <SeeTheDifferenceBodyContent>{body}</SeeTheDifferenceBodyContent>
        )}
        <SeeTheDifferenceMobileTable>
          <SeeTheDifferenceTableWrapper>
            <SeeTheDifferenceComparisonTable
              name={productName}
              valueProps={productValueProps}
            />
            <SeeTheDifferenceComparisonTable
              isCompetitorTable={true}
              name={competitorName}
              valueProps={competitorValueProps}
            />
          </SeeTheDifferenceTableWrapper>
        </SeeTheDifferenceMobileTable>
        {ctaButton && <CtaButton {...ctaButton} module={moduleName} />}
      </SeeTheDifferenceCopyAndCtaContainer>
    </SeeTheDifferenceContent>
  </SeeTheDifferenceWrapper>
);
