import styled from '@emotion/styled';

import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';
import { ReactComponent as CheckSvg } from '../images/orangeCheck.svg';
import { ReactComponent as CrossSvg } from '../images/grayCross.svg';

export const SeeTheDifferenceTableContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const SeeTheDifferenceTableHeader = styled.div<{
  isCompetitorTable?: boolean;
}>`
  align-items: flex-start;
  align-self: stretch;
  background-color: ${({ isCompetitorTable }) =>
    isCompetitorTable ? LOCAL_COLORS.navy400 : LOCAL_COLORS.surfaceBrandBold};
  color: ${LOCAL_COLORS.textInverse};
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.75rem 0 0.75rem ${SPACER.base};

  ${MEDIA_QUERIES.smUp} {
    padding: 0.75rem ${SPACER.base};
  }

  ${MEDIA_QUERIES.mdUp} {
    font-size: 1.25rem;
    padding: ${SPACER.base};
  }

  ${MEDIA_QUERIES.xlUp} {
    font-size: 1.25rem;
  }
`;

export const SeeTheDifferenceTableItems = styled.div`
  background-color: ${LOCAL_COLORS.surfaceWhite};
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
`;

export const SeeTheDifferenceTableItem = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 0.625rem;
  padding: 0.75rem ${SPACER.small};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.base};
  }

  ${MEDIA_QUERIES.lgUp} {
    align-items: center;
    padding: ${SPACER.base};
  }

  ${MEDIA_QUERIES.xlUp} {
    gap: 1.5rem;
  }
`;

export const SeeTheDifferenceIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 1.156rem;
  justify-content: center;
  max-width: 1rem;

  ${MEDIA_QUERIES.mdUp} {
    display: block;
    height: auto;
    max-width: 1.5rem;
  }

  ${MEDIA_QUERIES.xlUp} {
    max-width: 2rem;
  }
`;

export const SeeTheDifferenceCrossIcon = styled(CrossSvg)`
  flex: 0 0 1rem;
  height: auto;
  max-width: 1rem;

  ${MEDIA_QUERIES.mdUp} {
    flex: 0 0 1.5rem;
    max-width: 1.5rem;
  }

  ${MEDIA_QUERIES.xlUp} {
    flex: 0 0 2rem;
    max-width: 2rem;
  }
`;

export const SeeTheDifferenceCheckIcon = styled(CheckSvg)`
  flex: 0 0 1rem;
  height: auto;
  max-width: 1rem;

  ${MEDIA_QUERIES.mdUp} {
    flex: 0 0 1.5rem;
    max-width: 1.5rem;
  }

  ${MEDIA_QUERIES.xlUp} {
    flex: 0 0 2rem;
    max-width: 2rem;
  }
`;

export const SeeTheDifferenceValueProp = styled.div`
  color: ${LOCAL_COLORS.textDefault};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 1rem;
  }
`;
