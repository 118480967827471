import styled from '@emotion/styled';

import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';
import getContrastingColor from 'utils/getContrastingColor';

export const SeeTheDifferenceWrapper = styled.div<{
  backgroundColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ backgroundColor }) =>
    getContrastingColor(backgroundColor) || LOCAL_COLORS.textDefault};
`;

export const SeeTheDifferenceContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 90rem;
  padding: ${SPACER.x2large} ${SPACER.medium};

  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
    gap: 4rem;
    padding: 7.5rem 4.5rem;
  }

  ${MEDIA_QUERIES.xlUp} {
    gap: 5rem;
  }

  ${MEDIA_QUERIES.xxlUp} {
    gap: 6rem;
  }
`;

export const SeeTheDifferenceHeading = styled.h2<{ backgroundColor: string }>`
  align-self: stretch;
  color: ${({ backgroundColor }) =>
    getContrastingColor(backgroundColor) || LOCAL_COLORS.textDefault};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 110%;

  ${MEDIA_QUERIES.lgUp} {
    font-size: 3.25rem;
    letter-spacing: unset;
  }
`;

export const SeeTheDifferenceBodyContent = styled.div`
  line-height: 132%;
  width: 100%;

  ${MEDIA_QUERIES.lgUp} {
    line-height: 140%;
    font-size: 1.5rem;
  }
`;

export const SeeTheDifferenceDesktopTable = styled.div`
  display: none;

  ${MEDIA_QUERIES.lgUp} {
    display: block;
    flex: 1 0 50%;
    max-width: 32rem;
  }

  ${MEDIA_QUERIES.xlUp} {
    max-width: 38rem;
  }
`;

export const SeeTheDifferenceCopyAndCtaContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${MEDIA_QUERIES.lgUp} {
    align-items: flex-start;
    flex: 1 0 0;
    min-width: 348px;
  }

  ${MEDIA_QUERIES.xlUp} {
    align-items: flex-start;
    padding: 0;
  }
`;

export const SeeTheDifferenceMobileTable = styled.div`
  align-self: stretch;
  display: block;
  margin-bottom: 1.5rem;

  ${MEDIA_QUERIES.lgUp} {
    display: none;
  }
`;

export const SeeTheDifferenceTableWrapper = styled.div`
  display: grid;
  gap: 0 0.25rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  ${MEDIA_QUERIES.lgUp} {
    gap: 0 0.5rem;
  }
`;
