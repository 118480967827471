import { PartnersEntry } from 'schema/partnersSchema';
import { Partners } from './Partners';
import { SmartImage } from 'components/global/SmartImage';
import React from 'react';

export const ContentfulPartners = (props: PartnersEntry) => {
  const formattedProps = {
    ...props,
    partners: (
      <React.Fragment>
        {props.partners.map((imageProps) => (
          <SmartImage {...imageProps} />
        ))}
      </React.Fragment>
    ),
  };

  return <Partners {...formattedProps} />;
};
