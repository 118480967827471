import React, { ReactNode } from 'react';
import { ReactComponent as Arrow } from './images/arrow.svg';
import {
  Categories,
  Category,
  CategoryHeadline,
  CategoryImage,
  CategoryTag,
  Headline,
  InnerContainer,
  OuterContainer,
  Subheadline,
  ctaStyles,
} from './MultiCategoryHero.style';

import { LOCAL_COLORS } from 'styles/constants';
import { HOMEPAGE_MULTICATEGORY_CONTENT } from './content';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { Link } from 'gatsby';
import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';

export interface MultiCategoryHeroProps {
  headline?: ReactNode;
  subheadline?: string;
  cta?: CtaButtonProps;
  categories?: {
    image: ReactNode;
    headline: ReactNode;
    link: string;
    tag?: string;
  }[];
  backgroundColor?: string;
}

const moduleName = 'multiCategoryHero';

export const MultiCategoryHero = ({
  categories,
  headline,
  subheadline,
  backgroundColor = LOCAL_COLORS.surfaceBrandBold,
}: MultiCategoryHeroProps) => {
  const categoryArray = categories ?? HOMEPAGE_MULTICATEGORY_CONTENT.categories;

  const handleRxProductClick = () => {
    trackEvent(EVENTS.linkClicked, { productName: name, module: moduleName });
  };

  return (
    <OuterContainer backgroundColor={backgroundColor} data-module={moduleName}>
      <InnerContainer>
        <div>
          <Headline>
            {headline ?? HOMEPAGE_MULTICATEGORY_CONTENT.headline}
          </Headline>
          <Subheadline>
            {subheadline ?? HOMEPAGE_MULTICATEGORY_CONTENT.subheadline}
          </Subheadline>
          {HOMEPAGE_MULTICATEGORY_CONTENT.cta && (
            <CtaButton
              className={'multi-category-hero-cta'}
              module={moduleName}
              css={ctaStyles}
              {...HOMEPAGE_MULTICATEGORY_CONTENT.cta}
            />
          )}
        </div>
        <Categories>
          {categoryArray?.map((category, index) => (
            <Link key={index} to={category.link} onClick={handleRxProductClick}>
              <Category>
                <CategoryImage>{category.image}</CategoryImage>
                <CategoryHeadline>
                  {category.headline} <Arrow />
                </CategoryHeadline>
                {category.tag && <CategoryTag>{category.tag}</CategoryTag>}
              </Category>
            </Link>
          ))}
        </Categories>
      </InnerContainer>
    </OuterContainer>
  );
};
