import { ReactNode } from 'react';
import { Header, Logos, PartnersBox } from './Partners.style';

export interface PartnersProps {
  header: string;
  partners: ReactNode;
  backgroundColor?: string;
}

export const Partners = ({
  header,
  partners,
  backgroundColor,
}: PartnersProps) => {
  return (
    <PartnersBox $backgroundColor={backgroundColor}>
      <Header>{header}</Header>
      <Logos>{partners}</Logos>
    </PartnersBox>
  );
};
