import React from 'react';
import { RICH_TEXT_OPTIONS } from 'consts/contentful';

import { SeeTheDifference } from '.';
import type { SeeTheDifferenceEntry } from 'schema';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const ContentfulSeeTheDifference = ({
  body,
  competitorName,
  heading,
  productName,
  ...props
}: SeeTheDifferenceEntry) => (
  <SeeTheDifference
    body={body && documentToReactComponents(body, RICH_TEXT_OPTIONS)}
    competitorName={documentToReactComponents(
      competitorName,
      RICH_TEXT_OPTIONS,
    )}
    heading={documentToReactComponents(heading, RICH_TEXT_OPTIONS)}
    productName={documentToReactComponents(productName, RICH_TEXT_OPTIONS)}
    {...props}
  />
);
