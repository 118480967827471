import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import colors from 'styles/constants/colors';

const sharedStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${SPACER.large};
`;

export const PartnersBox = styled.section<{ $backgroundColor?: string }>`
  ${sharedStyles}
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : colors.surfaceWhite};
  padding: ${SPACER.x2large} ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    padding: ${SPACER.x2large} ${SPACER.xlarge};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: ${SPACER.xlarge} 72px;
  }
`;

export const Header = styled.h2`
  font-size: 20px;

  ${MEDIA_QUERIES.mdUp} {
    white-space: nowrap;
  }
`;

export const Logos = styled.div`
  ${sharedStyles}

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 80px;
  }
`;
