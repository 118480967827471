import React, { ReactNode } from 'react';

import {
  SeeTheDifferenceCheckIcon,
  SeeTheDifferenceCrossIcon,
  SeeTheDifferenceIconContainer,
  SeeTheDifferenceTableContent,
  SeeTheDifferenceTableHeader,
  SeeTheDifferenceTableItem,
  SeeTheDifferenceTableItems,
  SeeTheDifferenceValueProp,
} from './SeeTheDifferenceComparisonTable.style';

export type SeeTheDifferenceTableType = 'competitor' | 'product';

interface SeeTheDifferenceComparisonTableProps {
  isCompetitorTable?: boolean;
  name: ReactNode;
  valueProps: string[];
}

export const SeeTheDifferenceComparisonTable = ({
  isCompetitorTable,
  name,
  valueProps,
}: SeeTheDifferenceComparisonTableProps) => (
  <SeeTheDifferenceTableContent>
    <SeeTheDifferenceTableHeader isCompetitorTable={isCompetitorTable}>
      {name}
    </SeeTheDifferenceTableHeader>
    <SeeTheDifferenceTableItems>
      {valueProps.map((valueProp, index) => (
        <SeeTheDifferenceTableItem key={index}>
          <SeeTheDifferenceIconContainer>
            {isCompetitorTable ? (
              <SeeTheDifferenceCrossIcon />
            ) : (
              <SeeTheDifferenceCheckIcon />
            )}
          </SeeTheDifferenceIconContainer>
          <SeeTheDifferenceValueProp>{valueProp}</SeeTheDifferenceValueProp>
        </SeeTheDifferenceTableItem>
      ))}
    </SeeTheDifferenceTableItems>
  </SeeTheDifferenceTableContent>
);
